import { graphql, Link } from "gatsby";
import React from "react";
import BlockContent from "../components/BlockContent";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import { CheckIcon } from "@heroicons/react/outline";
import { NavLink } from "../components/Nav";

const Gjeldsforhandling = ({
  data: {
    sanityDebtNegotiate: {
      mainHeading,
      introduction,
      checkbox1,
      checkbox2,
      checkbox3,
    },
  },
}) => {
  return (
    <>
      <Nav />

      <div className="px-8">
        <div className="text-heading-4 md:text-heading-2 text-gray-dark mb-2 pt-8">
          {mainHeading}
        </div>

        <div className="py-8">
          <BlockContent blocks={introduction} />
        </div>

        <div className="flex flex-col place-content-center items-center ">
          <div className="md:w-1/2 w-full place-content-center bg-white shadow-md p-4 content-center">
            <div className="flex flex-row align-middle">
              <CheckIcon className="w-12 text-accent pr-2" />
              <div className="w-5/6 pt-2">{checkbox1}</div>
            </div>

            <div className="flex flex-row">
              <CheckIcon className="w-12 text-accent pr-2" />
              <div className="w-5/6 pt-2">{checkbox2}</div>
            </div>

            <div className="flex flex-row">
              <CheckIcon className="w-12 text-accent pr-2" />
              <div className="w-5/6 pt-2">{checkbox3}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 rounded-br-lg bg-gray-light flex place-content-center py-16">
        <Link
          to="/sok"
          className="text-gray-dark hover:text-gray-darker"
          activeClassName="text-gray-darker"
        >
          <div className="text-gray-dark">
            <div
              className="py-2 px-6
              font-bold mt-4 rounded-full transition-colors bg-accent hover:bg-accent-hover text-gray-dark"
            >
              Ta meg til skjemaet!
            </div>
          </div>
        </Link>
      </div>
      <CookieBanner />
      <Footer />
    </>
  );
};

export const query = graphql`
  query {
    sanityDebtNegotiate(_id: { regex: "/debt-negotiate$/" }) {
      mainHeading
      introduction: _rawIntroduction
      checkbox1
      checkbox2
      checkbox3
    }
  }
`;

export default Gjeldsforhandling;
